
import GoogleMyBusinessLoginCard from '@/components/google/GoogleMyBusinessLoginCard.vue';
import { GoogleBusinessPlatformData, PublicBot, SupportedPlatform } from 'ignite360-core';
import _merge from 'lodash.merge';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'public-social-login-google-my-business',
  components: { GoogleMyBusinessLoginCard },
})
export default class PublicSocialLoginGoogleMyBusiness extends Vue {
  @Prop({ required: true, type: Object })
  readonly bot!: PublicBot;

  onConnect(data: Partial<GoogleBusinessPlatformData>) {
    const newBot: PublicBot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.GoogleBusiness]: data,
      },
    });
    this.$emit('update:bot', newBot);
  }

  onDisconnect() {
    const newBot: PublicBot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.GoogleBusiness]: {
          accountId: '',
          locationId: '',
        },
      },
    });
    this.$emit('update:bot', newBot);
  }
}
