
import { GoogleMyBusinessApi } from '@/api';
import { GENERIC_ERROR_DESCRIPTION, tenant } from '@/constants';
import { AppRoute } from '@/router/routes';
import { SelectOption } from '@/types';
import { copyToClipboard } from '@/utilities';
import {
  GoogleBusinessPlatformData,
  GoogleMyBusinessAccount,
  GoogleMyBusinessLocation,
} from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'google-my-business-login-card',
})
export default class GoogleMyBusinessLoginCard extends Vue {
  @Prop({ required: true, type: String })
  readonly botId!: string;

  @Prop({ required: true, type: String })
  readonly accountId!: string;

  @Prop({ required: true, type: String })
  readonly locationId!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hideCopyButton!: boolean;

  @Prop({ required: false, type: String, default: 'Connect to location' })
  readonly header!: string;

  loadedAccounts: GoogleMyBusinessAccount[] = [];
  loadedLocations: GoogleMyBusinessLocation[] = [];

  selectedAccountId = '';
  selectedLocationId = '';

  isLoading = false;
  isSubmitting = false;
  isDisconnecting = false;

  get canSave(): boolean {
    return !!(this.selectedAccount && this.selectedLocation);
  }

  get canDisconnect(): boolean {
    return !!(this.locationId && this.accountId);
  }

  get hasChanges(): boolean {
    return this.selectedLocationId !== this.locationId || this.selectedAccountId !== this.accountId;
  }

  get accountOptions(): SelectOption[] {
    return this.loadedAccounts.map((account) => ({
      value: account._id,
      label: account.name,
    }));
  }

  get selectedAccount(): GoogleMyBusinessAccount | undefined {
    return this.loadedAccounts.find((account) => account._id === this.selectedAccountId);
  }

  get locationOptions(): SelectOption[] {
    return this.loadedLocations.map((location) => ({
      value: location._id,
      label: location.name,
    }));
  }

  get selectedLocation(): GoogleMyBusinessLocation | undefined {
    return this.loadedLocations.find((location) => location._id === this.selectedLocationId);
  }

  get authTokenId(): string | null | undefined {
    return this.$route.query.authToken as string | undefined;
  }

  async loadAccounts() {
    if (!this.authTokenId) {
      return;
    }
    this.isLoading = true;
    try {
      this.loadedAccounts = await GoogleMyBusinessApi.loadAccounts(this.authTokenId);
    } catch (e) {
      this.$notify.error({
        title: 'Loading Google-My-Business-accounts failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async loadLocations() {
    if (!this.authTokenId) {
      return;
    }
    this.isLoading = true;
    try {
      this.loadedLocations = await GoogleMyBusinessApi.loadLocations(
        this.authTokenId,
        this.selectedAccountId,
      );
    } catch (e) {
      this.$notify.error({
        title: 'Loading Google-My-Business-locations failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }

  onLogin() {
    const currentLocationWithoutQueryParams = window.location.href.split('?')[0];
    const baseUrl = tenant.apiBaseUrl || 'http://localhost:4000';
    window.location.href = `${baseUrl}/public/google-my-business/auth/login?redirect=${currentLocationWithoutQueryParams}`;
  }

  async onSubmit() {
    if (!this.selectedLocation || !this.selectedAccount || !this.authTokenId) return;
    this.isSubmitting = true;
    try {
      await GoogleMyBusinessApi.connectBotWithGoogleMyBusiness(
        this.botId,
        this.authTokenId,
        this.selectedAccount._id,
        this.selectedLocation._id,
      );
      const googleBusinessPlatformData: Partial<GoogleBusinessPlatformData> = {
        accountId: this.selectedAccount._id,
        locationId: this.selectedLocation._id,
      };
      this.$emit('connect', googleBusinessPlatformData);
      this.$notify.success('Successfully connected to Google-My-Business');
    } catch (e) {
      this.$notify.error({
        title: 'Connecting to Google-My-Business failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  async onDisconnect() {
    if (!this.canDisconnect) return;
    this.isDisconnecting = true;
    try {
      await GoogleMyBusinessApi.disconnectBotFromGoogleBusiness(this.botId);
      this.$emit('disconnect');
      this.$notify.success('Successfully disconnected from Google-My-Business');
    } catch (e) {
      this.$notify.error({
        title: 'Disconnecting from Google-My-Business failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isDisconnecting = false;
    }
  }

  copyPublicLoginUrl() {
    const resolvedRoute = this.$router.resolve({
      name: AppRoute.PublicSocialLoginGoogleMyBusiness,
      params: { id: this.botId },
    });
    copyToClipboard(location.origin + resolvedRoute.href);
  }

  @Watch('loadedAccounts')
  async onLoadedAccountsChange() {
    if (this.selectedAccountId) {
      return this.loadLocations();
    }
  }

  @Watch('selectedAccountId')
  async onSelectedAccountIdChange() {
    if (this.loadedAccounts.length && this.selectedAccountId) {
      return this.loadLocations();
    }
  }

  @Watch('accountId', { immediate: true })
  resetAccount() {
    this.selectedAccountId = this.accountId;
  }

  @Watch('locationId', { immediate: true })
  resetLocation() {
    this.selectedLocationId = this.locationId;
  }

  @Watch('authTokenId', { immediate: true })
  onAuthTokenChange() {
    if (this.authTokenId) {
      return this.loadAccounts();
    }
  }
}
